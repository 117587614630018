import React,{Suspense, lazy} from 'react';
import { Routes, Route } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from './app/components/Loader';
import Loader2 from './app/components/Loader2';
import Box from '@mui/material/Box';
// import EditCv from './app/components/EditCv';

const Login = lazy(() => import("./app/auth/Login"));
const Logout = lazy(() => import("./app/auth/Logout"));
const Register = lazy(() => import("./app/auth/Register"));
const CreateAccount = lazy(() => import("./app/auth/CreateAccount"));
const ForgotPassword = lazy(() => import("./app/auth/ForgotPassword"));
const Success = lazy(() => import("./app/auth/Success"));
const Errors = lazy(() => import("./app/auth/Errors"));
const ParentalConsent = lazy(() => import("./app/auth/ParentalConsent"));
const MyFeed = lazy(() => import("./app/components/MyFeed"));
const Profile = lazy(() => import("./app/components/Profile"));
const SuccessParental = lazy(() => import("./app/auth/SuccessParental"));
const Portfolio = lazy(() => import("./app/components/Portfolio"));
const CreateProject = lazy(() => import("./app/components/CreateProject"));
const EditProject = lazy(() => import("./app/components/EditProject"));
const CreateProjectPreview = lazy(() => import("./app/components/CreateProjectPreview"));
const ProfileFriends = lazy(() => import("./app/components/ProfileFriends"));
const ProfileEmpty = lazy(() => import("./app/components/ProfileEmpty"));
const Header = lazy(() => import("./app/components/Header"));
const CreateCV = lazy(() => import("./app/components/CreateCV"));
const ViewCV = lazy(() => import("./app/components/ViewCV"));
const CreateCVDetails = lazy(() => import("./app/components/CreateCVDetails"));
//const Loader = lazy(() => import("./app/components/Loader"));
const Footer = lazy(() => import("./app/components/Footer"));
const Cookies = lazy(() => import("./app/components/Cookies"));
const AdminLogin = lazy(() => import("./app/admin/Login"));
const Dashboard = lazy(() => import("./app/admin/Dashboard"));
const EditCv = lazy(() => import("./app/components/EditCv"));

//const Loader2 = lazy(() => import("./app/components/Loader2"));
const ViewCVNew = lazy(() => import("./app/components/ViewCVNew"));

const router = (
    <Suspense fallback={<Box className='appLoader'><Loader2 /></Box>}>
        <Routes basename={'/app'}>
            {/* <Route path='/' element={ <Login /> } /> */}
            <Route path={`${process.env.PUBLIC_URL}/*`} element={<Login />} />
            <Route path={`${process.env.PUBLIC_URL}/login`} element={ <Login /> } />
            <Route path={`${process.env.PUBLIC_URL}/logout`} element={ <Logout /> } />
            <Route path={`${process.env.PUBLIC_URL}/register`} element={<Register />} />
            <Route path={`${process.env.PUBLIC_URL}/create-account`} element={<CreateAccount />} />
            <Route path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgotPassword />} />
            <Route path={`${process.env.PUBLIC_URL}/success/:type`} element={<Success />} />
            <Route path={`${process.env.PUBLIC_URL}/errors`} element={<Errors />} />
            <Route path={`${process.env.PUBLIC_URL}/parental-consent`} element={<ParentalConsent />} />
            <Route path={`${process.env.PUBLIC_URL}/my-feed`} element={<MyFeed />} />
            <Route path={`${process.env.PUBLIC_URL}/profile/:id`} element={<Profile />} />
            <Route path={`${process.env.PUBLIC_URL}/success-parental/:id`} element={<SuccessParental />} />
            <Route path={`${process.env.PUBLIC_URL}/portfolio`} element={<Portfolio />} />
            <Route path={`${process.env.PUBLIC_URL}/create-project`} element={<CreateProject />} />
            <Route path={`${process.env.PUBLIC_URL}/edit-project/:id`} element={<EditProject />} />
            <Route key={'project'} path={`${process.env.PUBLIC_URL}/project/:username/:id`} element={<CreateProjectPreview />} />
            <Route path={`${process.env.PUBLIC_URL}/profile-friends`} element={<ProfileFriends />} />
            <Route path={`${process.env.PUBLIC_URL}/profile-empty`} element={<ProfileEmpty />} />
            <Route path={`${process.env.PUBLIC_URL}/header`} element={<Header />} />
            <Route path={`${process.env.PUBLIC_URL}/create-cv`} element={<CreateCV />} />
            <Route path={`${process.env.PUBLIC_URL}/cv/:username/:id`} element={<ViewCV />} />
            <Route path={`${process.env.PUBLIC_URL}/create-cv-details`} element={<CreateCVDetails />} />
            {/* <Route path="/loader" element={<Loader />} /> */}
            <Route path={`${process.env.PUBLIC_URL}/footer`} element={<Footer />} />
            <Route path={`${process.env.PUBLIC_URL}/cookies`} element={<Cookies />} />
            <Route path={`${process.env.PUBLIC_URL}/admin1003/login`} element={<AdminLogin />} />
            <Route path={`${process.env.PUBLIC_URL}/admin1003/dashboard`} element={<Dashboard />} />
            <Route path = {`${process.env.PUBLIC_URL}/edit-cv/:id`} element={<EditCv />} />
            <Route path = {`${process.env.PUBLIC_URL}/view-cv-new`} element={<ViewCVNew />} />
          

            {/* <Route path="/loader2" element={<Loader2 />} /> */}
            
        </Routes>
    </Suspense>
);

export default router;